// Here you can add other styles

.pagination .active .page-link {
    background-color: rgb(214, 178, 49); /* Change to your preferred background color */
    border-color: rgb(214, 178, 49); /* Change the border color if needed */
    color: white; /* Change the text color */
  }
  .pagination .page-link {
    color: black; /* Set the text color for all pagination links */
  }

  .time-input-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 10px 0;
}

.time-label {
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
}

.custom-time-input {
  padding: 2px 8px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  outline: none;
  transition: border 0.3s, box-shadow 0.3s;
}

.custom-time-input:hover {
  border-color: #888;
}

.custom-time-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.custom-time-input::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(50%);
}
